import { type GridSettings } from 'light-react-grid';

import { Breakpoint } from '~/v1/constants/breakpoint';

export const GRID_SETTINGS: GridSettings = {
  breakpoints: {
    [Breakpoint.SM]: {
      columns: 5,
      gutterSize: 0,
      maxWidth: 767,
    },
    [Breakpoint.MD]: {
      columns: 7,
      gutterSize: 0,
      maxWidth: 1279,
      minWidth: 768,
    },
    [Breakpoint.LG]: {
      columns: 9,
      gutterSize: 0,
      minWidth: 1280,
    },
  },
  prefixes: {
    grid: 'g',
    gridColumn: 'gc',
  },
};
