import { createContext, useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum-slim';
import { sendGTMEvent } from '@next/third-parties/google';
import { useLocalStorage } from 'usehooks-ts';

import { Datadog } from '~/ui/components/analytics/providers/datadog';
import { Gtm } from '~/ui/components/analytics/providers/gtm';
import { context, environment } from '~/v1/constants/environment';
import { StorageKey } from '~/v1/constants/storageKey';

import { type ConsentValue } from './types';

export interface AnalyticsContext {
  setConsent: (consent: ConsentValue) => void;
}

export const AnalyticsContext = createContext<AnalyticsContext>({
  setConsent: () => null,
});

enum TrafficType {
  INTERNAL,
  EXTERNAL,
  DEVELOPMENT,
}

export function AnalyticsProvider({ children }: { children: React.ReactNode }) {
  // TODO: consent will be used soon!
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [consent, setConsent] = useLocalStorage<ConsentValue>(StorageKey.CONSENT, null, {
    initializeWithValue: false,
  });
  const [trafficType, setTrafficType] = useLocalStorage(
    StorageKey.TRAFFIC_TYPE,
    TrafficType.EXTERNAL,
    {
      initializeWithValue: false,
    },
  );

  useEffect(() => {
    // if we're not in production, we don't need to check if the user is internal
    if (
      context !== 'production' ||
      environment !== 'prod' ||
      trafficType === TrafficType.DEVELOPMENT
    ) {
      setTrafficType(TrafficType.DEVELOPMENT);
      return;
    }

    // if we already know the user is internal (from a previous session), we don't need to check again
    if (trafficType === TrafficType.INTERNAL) {
      return;
    }

    // We would usually use a query parameter to avoid caching but in this case
    // we know the user _was_ able to connect to intranet at some point
    const intranetUrl = new URL('/static/favicon.ico', 'https://intranet.mellon.org');
    const image = new Image();
    image.addEventListener('load', () => setTrafficType(TrafficType.INTERNAL));
    image.addEventListener('error', () => setTrafficType(TrafficType.EXTERNAL));
    image.src = intranetUrl.toString();
  }, [setTrafficType, trafficType]);

  useEffect(() => {
    let trafficTypeLabel: string;
    switch (trafficType) {
      case TrafficType.INTERNAL:
        trafficTypeLabel = 'internal';
        break;
      case TrafficType.EXTERNAL:
        trafficTypeLabel = 'external';
        break;
      case TrafficType.DEVELOPMENT:
        // this label is also used in GTM and must be kept in sync
        trafficTypeLabel = 'development';
        break;
    }
    datadogRum.setGlobalContextProperty('trafficType', trafficTypeLabel);
    sendGTMEvent([
      'set',
      {
        traffic_type: trafficTypeLabel,
      },
    ]);
  }, [trafficType]);

  return (
    // track event can be imported directly but is included in context for backwards compat
    <AnalyticsContext.Provider value={{ setConsent }}>
      <Datadog />
      <Gtm />
      {children}
    </AnalyticsContext.Provider>
  );
}
