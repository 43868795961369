import { GoogleTagManager } from '@next/third-parties/google';

export function Gtm() {
  return (
    <GoogleTagManager
      gtmId="GTM-TS43CMS"
      // set default consent on init
      dataLayer={[
        'consent',
        'default',
        JSON.stringify({
          ad_storage: 'granted',
          analytics_storage: 'granted',
        }),
      ]}
    />
  );
}
